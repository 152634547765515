// extracted by mini-css-extract-plugin
export var column = "UserBareMetalProject__column__bEP7w";
export var container = "UserBareMetalProject__container__ODyX7";
export var contentWrapper = "UserBareMetalProject__contentWrapper__asnse";
export var flex = "UserBareMetalProject__flex__ueTRY";
export var flexColumn = "UserBareMetalProject__flexColumn__qdjLr";
export var gap1 = "UserBareMetalProject__gap1__MuXJD";
export var gap2 = "UserBareMetalProject__gap2__Sa8Zj";
export var gap3 = "UserBareMetalProject__gap3__CGaAd";
export var gap4 = "UserBareMetalProject__gap4__HRP4y";
export var gap5 = "UserBareMetalProject__gap5__kVaRQ";
export var row = "UserBareMetalProject__row__bDYZn";