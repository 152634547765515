// extracted by mini-css-extract-plugin
export var column = "ProjectDetailsProviders__column__JGC3Q";
export var datacentersCountText = "ProjectDetailsProviders__datacentersCountText__eZumS";
export var editButton = "ProjectDetailsProviders__editButton__vcfai";
export var emptyProviders = "ProjectDetailsProviders__emptyProviders__l7YcD";
export var flex = "ProjectDetailsProviders__flex__NJSu6";
export var flexColumn = "ProjectDetailsProviders__flexColumn__qeGDl";
export var gap1 = "ProjectDetailsProviders__gap1__ue2BP";
export var gap2 = "ProjectDetailsProviders__gap2__K20yj";
export var gap3 = "ProjectDetailsProviders__gap3__Z5ToY";
export var gap4 = "ProjectDetailsProviders__gap4__k3f8l";
export var gap5 = "ProjectDetailsProviders__gap5__G0iDF";
export var headingContentWrapper = "ProjectDetailsProviders__headingContentWrapper__vS0W1";
export var mapContainer = "ProjectDetailsProviders__mapContainer__PMZOD";
export var mapElement = "ProjectDetailsProviders__mapElement__fg4x8";
export var providerDetailRow = "ProjectDetailsProviders__providerDetailRow__ndAoQ";
export var providerHeading = "ProjectDetailsProviders__providerHeading__tz3h4";
export var providerImage = "ProjectDetailsProviders__providerImage__mCvya";
export var providerName = "ProjectDetailsProviders__providerName__LYYjd";
export var providerPanel = "ProjectDetailsProviders__providerPanel__TsJrF";
export var providerPanelBody = "ProjectDetailsProviders__providerPanelBody__Cxbet";
export var providerPanelHeadingContainer = "ProjectDetailsProviders__providerPanelHeadingContainer__vWTZg";
export var providerPanelImageAndNameWrapper = "ProjectDetailsProviders__providerPanelImageAndNameWrapper__bRySq";
export var providerTable = "ProjectDetailsProviders__providerTable__sABev";
export var providerTableHead = "ProjectDetailsProviders__providerTableHead__wAbdz";
export var providerTableHeadingContainer = "ProjectDetailsProviders__providerTableHeadingContainer__O4q82";
export var providerTitle = "ProjectDetailsProviders__providerTitle__uXtOF";
export var providersContentWrapper = "ProjectDetailsProviders__providersContentWrapper__VgKvh";
export var providersWrapper = "ProjectDetailsProviders__providersWrapper__xvbAU";
export var quantityText = "ProjectDetailsProviders__quantityText__bZXOU";
export var roleText = "ProjectDetailsProviders__roleText__eejPB";
export var row = "ProjectDetailsProviders__row__jC2aj";