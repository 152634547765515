// extracted by mini-css-extract-plugin
export var column = "ProjectProviderTile__column__KStEJ";
export var container = "ProjectProviderTile__container__jYSX3";
export var flex = "ProjectProviderTile__flex__pKfuU";
export var flexColumn = "ProjectProviderTile__flexColumn__qvmxz";
export var gap1 = "ProjectProviderTile__gap1__QHq_h";
export var gap2 = "ProjectProviderTile__gap2__KwNBi";
export var gap3 = "ProjectProviderTile__gap3__tVg4a";
export var gap4 = "ProjectProviderTile__gap4__kkyVj";
export var gap5 = "ProjectProviderTile__gap5__WutcL";
export var logo = "ProjectProviderTile__logo__HEFUv";
export var providerName = "ProjectProviderTile__providerName__z3QoW";
export var row = "ProjectProviderTile__row__aTxfr";