// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../../../styleguide/components/Paragraph/P.res.js";
import * as ID from "../../../../../libs/ID.res.js";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconBuilding from "../../../../../styleguide/icons/IconBuilding.res.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectProviderTile from "../ProjectProviderTile/ProjectProviderTile.res.js";
import * as ProjectDetailsProvidersScss from "./ProjectDetailsProviders.scss";

var css = ProjectDetailsProvidersScss;

function providerTextWithCountDistinction(x) {
  if (x !== 1) {
    return " providers";
  } else {
    return " provider";
  }
}

function ProjectDetailsProviders(props) {
  var providers = props.providers;
  var userRole = props.userRole;
  var tmp;
  switch (userRole) {
    case "Provider" :
    case "Visitor" :
        tmp = null;
        break;
    default:
      tmp = JsxRuntime.jsx(Button.AsLink.make, {
            href: Routes_Project.update(props.projectId),
            size: "SM",
            color: "Teal",
            ghost: true,
            buttonClassName: css.editButton,
            children: "Edit Providers"
          });
  }
  var tmp$1;
  switch (userRole) {
    case "Provider" :
        tmp$1 = "Your provider has been invited to respond to this project.";
        break;
    case "Visitor" :
        tmp$1 = "";
        break;
    default:
      tmp$1 = "The following providers have been matched to your project. Providers may be added or removed at the discretion of Datacenters.com. Only providers that have accepted to participate are shown.";
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("h2", {
                                    children: "Providers",
                                    className: css.providerHeading
                                  })
                            }),
                        tmp
                      ],
                      className: css.headingContentWrapper
                    }),
                JsxRuntime.jsx("p", {
                      children: tmp$1,
                      className: css.roleText
                    }),
                JsxRuntime.jsx("div", {
                      children: providers.length !== 0 ? Belt_Array.map(providers, (function (provider) {
                                return JsxRuntime.jsx(ProjectProviderTile.make, {
                                            provider: provider
                                          }, ID.toString(provider.id) + "-Provider-Selection-Tile");
                              })) : JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(IconBuilding.make, {
                                      size: "XXXXXL",
                                      color: "Teal"
                                    }),
                                JsxRuntime.jsx(P.make, {
                                      children: "Providers are still reviewing this project and acceptance is pending."
                                    })
                              ],
                              className: css.emptyProviders
                            }),
                      className: css.providersWrapper
                    })
              ],
              className: css.providersContentWrapper
            });
}

var make = ProjectDetailsProviders;

export {
  css ,
  providerTextWithCountDistinction ,
  make ,
}
/* css Not a pure module */
