// extracted by mini-css-extract-plugin
export var colLeft = "UserBareMetalProject_Details__colLeft__CSWO7";
export var colRight = "UserBareMetalProject_Details__colRight__zS2Ku";
export var column = "UserBareMetalProject_Details__column__CH1Bv";
export var columnWrapper = "UserBareMetalProject_Details__columnWrapper__dz4K8";
export var descriptionContent = "UserBareMetalProject_Details__descriptionContent__cQaAy";
export var descriptionHeading = "UserBareMetalProject_Details__descriptionHeading__M8KXS";
export var flex = "UserBareMetalProject_Details__flex__ts5ol";
export var flexColumn = "UserBareMetalProject_Details__flexColumn__Br7QG";
export var gap1 = "UserBareMetalProject_Details__gap1__BiOyv";
export var gap2 = "UserBareMetalProject_Details__gap2__F54iZ";
export var gap3 = "UserBareMetalProject_Details__gap3__Qw8LC";
export var gap4 = "UserBareMetalProject_Details__gap4__d9T33";
export var gap5 = "UserBareMetalProject_Details__gap5__hHvoo";
export var lastUpdated = "UserBareMetalProject_Details__lastUpdated__Ozibt";
export var reviewStatusWarning = "UserBareMetalProject_Details__reviewStatusWarning__u35jt";
export var row = "UserBareMetalProject_Details__row__UK8fy";